<template>
    <div class="d-flex gap-3 flex-column">
        <h1 class="fw-bold">Events Management</h1>
        <span>Tatsing Group > Events</span>
        <Preloader class="position-absolute w-100 h-100" v-show="isLoading && !$store.state.isLoading" />
        <div class="card shadow-sm d-flex flex-column justify-content-center">
            <h4 class="text-center py-2" :class="{'text-success': $store.state.event.isSuccess, 'text-danger': !$store.state.event.isSuccess }">
            <span v-show="$store.state.event.fail">{{ $route.query.id ? 'Event with ID '+$route.query.id+" is " : '' }}</span><span v-show="message">{{ message }}</span>
            </h4>
            <form enctype="multipart/form-data" @submit.prevent="insert()">
                <div class="rounded-top py-3 px-4">
                    <h4 class="fw-bold" style="font-size: larger;">{{ event_label }} Event</h4>
                </div>
                <div class="rounded-bottom">
                    <div class="d-flex align-items-center flex-column w-100">
                        <div class="w-100 border-bottom px-4 py-2">
                            <div class="row px-2">
                                <label for="title" style="font-size: small;">Title</label>
                                    
                                <input v-model="event_data.title" id="title" class="mt-2 border rounded py-2 px-2 w-100" type="text" placeholder="Event Title" required>
                            </div>
                            <div class="text-danger align-middle" v-show="title_errors.length">
                                <ul>
                                    <li v-for="error in title_errors" :key="error">
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="w-100 border-bottom px-4 py-2">
                            <div class="row px-2">
                                    <label for="location"
                                        style="font-size: small;">Location</label>
                                    <input v-model="event_data.location" id="location" class="mt-2 border rounded py-2 px-2 w-100" type="text" placeholder="Event Location"
                                        required>
                                        <div class="text-danger align-middle" v-show="location_errors.length">
                                        <ul>
                                            <li v-for="error in location_errors" :key="error">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="w-100 border-bottom px-4 py-2">
                            <div class="row">
                                <label for="date" style="font-size: small;">Event Date/Time</label>
                                <Datepicker v-model="event_data.event_date_time_orig" class="mt-2 rounded py-2 px-2 w-100" format="MMM. dd, yyyy eee | hh:mm a" placeholder="Event Date" :is24="false" :required="true" />
                                <div class="my-2">
                                    <strong>Remember</strong>: when time is set to "12:00 AM" it means "To be Announced".
                                </div>
                                <div class="text-danger align-middle" v-show="date_errors.length">
                                    <ul>
                                        <li v-for="error in date_errors" :key="error">
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 border-bottom px-4 py-2">
                            <div class="row px-2">
                                <label for="description" style="font-size: small;">Description</label>
                                <textarea id="description" v-model="event_data.description" class="mt-2 border rounded py-2 px-2 w-100" placeholder="Event Description"></textarea>
                                <div class="text-danger align-middle" v-show="description_errors.length">
                                    <ul>
                                        <li v-for="error in description_errors" :key="error">
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="w-100 border-bottom px-4 py-2">
                            <div class="row">
                                <label class="fw-bold">Gallery</label>

                                <div class="d-flex flex-wrap gap-3">
                                    <div class="mt-2" v-for="n in no_of_file" :key="n">
                                        <div :id="n" style="border: 4px red dashed; width: 100px; height: 100px;" v-show="!file_data[n-1]">
                                            <button type="button" class="bg-transparent border-0 text-center h-100 w-100"
                                            @click="photo_edit_clicked(n)">
                                                <i class="bi bi-plus fs-2"></i>
                                            </button>
                                            <input :id="'upload_'+n" name="uploadfile" type="file" accept="image/*" class="d-none" @change="pick_photo" />
                                        </div>
                                        <div :id="'img'+n" class="position-relative" style="width: 100px; height: 100px;" v-show="file_data[n-1]">
                                            <button type="button"
                                            class="bg-transparent border-0 position-absolute" style="top: -20px; right: -10px"
                                            @click="delete_picked_photo(n)">
                                                <i class="bi bi-x-circle"></i>
                                            </button>
                                            <img :src="file_data[n-1]" :alt="'img'+n" class="h-100 w-100" v-if="!notanImage">
                                            <div class="h-100 w-100" v-if="notanImage">
                                                Not an Image
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-danger align-middle" v-show="gallery_errors.length">
                                    <ul>
                                        <li v-for="error in gallery_errors" :key="error">
                                            {{ error }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-end p-2 mx-2">
                    <button id="submitter" type="submit" class="bg-theme text-white p-2 rounded">{{ event_button }} Event</button>
                </div>
            </form>
        </div>
    </div>
</template>

<style scoped src='../../../assets/css/inner.css'></style>

<script>
import Preloader from "../../../components/Preloader.vue";
import { createNamespacedHelpers } from "vuex";
createNamespacedHelpers('tablecontroller', 'event')
import { format } from 'date-fns/fp'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

export default {
  name: 'Event',
  components: {
    Datepicker,
    Preloader,
  },
  data() {
    return {
        data: {},
        no_of_file: 1,
        event_data: {
            title: '',
            location: '',
            event_date_time_orig: '',
            description: '',
            gallery: [],
            file_names: []
        },
        file_data: [],
        notanImage: false,
        to_editPhoto: true,
        photo_properties: [],
        method: '',
        event_label: 'Create',
        event_button: 'Create',
        isUpdating: false,
        message: ''

    }
  },
  async mounted() {
    if(this.$store.getters['get_perm']('showEvent')) {
        if(this.$route.query.id) {
            if(this.$store.state.event.single_event_data.id == this.$route.query.id) {
                this.method = 'PATCH'

                this.isUpdating = true

                this.event_label = 'Update';
                this.event_button = 'Update';
                Object.assign(this.event_data, this.$store.state.event.single_event_data)
            }
            else {
                await this.$store.dispatch('event/getEvent', this.$route.query.id).then(() => {
                    this.message = this.$store.state.event.message
                    if(this.message.includes('Not Found')) return
                    this.method = 'PATCH'
                    this.isUpdating = true
                    this.event_label = 'Update';
                    this.event_button = 'Update';
                    
                    Object.assign(this.event_data, this.$store.state.event.single_event_data)
                });
            }
            this.event_data.event_date_time_orig = this.event_data.event_date_time
            this.no_of_file = this.event_data.file_names.length + 1
            this.event_data.file_names.forEach(element => {
                this.file_data.push('https://tsg-api.tatsing.com.ph/api/v1/public/storage/events/'+this.event_data.id+'/'+element)
            })
        }
    }
  },
  methods: {
    photo_edit_clicked(n) {
        document.getElementById('upload_'+n).click()
    },
    pick_photo(event) {
        const num = this.photo_properties.length
        const files = event.target.files
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
            this.file_data.push(fileReader.result)
        })
        fileReader.readAsDataURL(files[0])
        this.photo_properties[num] = files[0]
        if(!(this.photo_properties[num]['type'].split('/')[0] === 'image')) {
            this.notanImage = true
        }
        else {
            this.notanImage = false
            this.to_editPhoto = true;
            this.no_of_file++
        }
    },
    delete_picked_photo(n) {
        if(this.file_data[n-1].includes('storage')) this.event_data.file_names.splice(n-1, 1)
        this.file_data.splice(n-1, 1)
        this.photo_properties.splice(n-1, 1)
        if(!this.notanImage) this.no_of_file--
    },
    async upload() {
        var formData = new FormData();
        this.photo_properties.forEach(element => {
            formData.append('file_data[]', element)
        })
        await this.$store.dispatch("event/upload", formData)
    },
    async insert() {
        this.event_data.event_date_time = format('yyyy-MM-dd H:mm:ss', new Date(this.event_data.event_date_time_orig))
        
        if(this.method === 'PATCH') {
            if(this.photo_properties.length){
                await this.upload().then(() => {
                    Promise.resolve(
                        Object.assign(this.event_data, { gallery: this.$store.state.event.uploaded_names })
                        ).then(() => {
                    this.$store.dispatch('event/updateEvent', this.event_data).then(() => {
                        this.message = this.$store.state.event.message
                    })
                })
            })
            }
            else {
                await this.$store.dispatch('event/updateEvent', this.event_data).then(() => {
                    this.message = this.$store.state.event.message
                })
            }
        }
        else {
            if(this.photo_properties.length){
                await this.upload().then(() => {
                    Promise.resolve(
                        Object.assign(this.event_data, { gallery: this.$store.state.event.uploaded_names })
                        ).then(() => {
                    this.$store.dispatch('event/createEvent', this.event_data).then(() => {
                        this.message = this.$store.state.event.message
                        if(this.$store.state.event.isSuccess) {
                            this.event_data = {
                                title: '',
                                location: '',
                                event_date_time_orig: '',
                                description: '',
                                gallery: [],
                                file_names: []
                            }
                            this.file_data = []
                            this.photo_properties = []
                            this.no_of_file = 1
                        }
                    })
                })
            })
            }
            else {
                await this.$store.dispatch('event/createEvent', this.event_data).then(() => {
                    this.message = this.$store.state.event.message
                    if(this.$store.state.event.isSuccess) {
                        this.event_data = {
                            title: '',
                            location: '',
                            event_date_time_orig: '',
                            description: '',
                            gallery: [],
                            file_names: []
                        }
                        this.file_data = []
                        this.photo_properties = []
                        this.no_of_file = 1
                    }
                })
            }
        }
    }
  },
  computed: {
    isLoading: {
        get() {
            return this.$store.state.event.isLoading
        }
    },
    title_errors: {
        get() {
            return this.$store.state.event.errors.title || []
        }
    },
    location_errors: {
        get() {
            return this.$store.state.event.errors.location || []
        }
    },
    description_errors: {
        get() {
            return this.$store.state.event.errors.description || []
        }
    },
    date_errors: {
        get() {
            return this.$store.state.event.errors.event_date_time || []
        }
    },
    gallery_errors: {
        get() {
            return this.$store.state.event.errors.gallery || []
        }
    },
  },
}
</script>